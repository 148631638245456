// assets/scss/login.scss

@import 'colors';

body {
  &#login {
    background: linear-gradient(#1895b3, #000 70%);
    color: #fff;

    nav {
      &.navbar {
        margin-bottom: 0;
        min-height: auto;
        position: fixed;
        text-align: left;

        &::before,
        &::after {
          content: none;
        }
      }
    }

    .navbar-text {
      margin-bottom: 0;
      margin-left: auto;
    }
  }

  .body-container {
    margin: 5px auto 50px;
    width: 100%;
  }
}

h4 {
  margin-top: 0;
}

p {
  &.note {
    font-size: 12px;
  }

  &.footer {
    margin-top: 20px;
  }
}

.logo-hide-text {
  display: inline-block;
  overflow: hidden;
  position: relative;
  top: 5px;
  width: 28px;

  img {
    height: 26px;
  }
}

.jumbotron {
  background-color: inherit;
  margin-top: 125px;
  padding-bottom: 0;
  text-align: center;

  .sio-apps {
    font-size: 40px;
    height: 44px;
    letter-spacing: 0.51px;
    line-height: 44px;
    text-align: center;
    width: 100%;
  }

  .forgot-password-n {
    color: $color-default-reverse;
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    padding-top: 10px;
    text-align: center;

    a {
      color: $color-default-reverse;
    }
  }
}

.masthead {
  margin-top: 10%;
}

@media (max-width: 767px) {
  .masthead {
    margin-top: 20%;
  }
}

#google-image-button {
  margin-bottom: 10px;
}

#google-signin-button {
  padding: 0;
  text-transform: uppercase;

  &:hover {
    background-color: $background-good-focus;
  }
}

.g-signin2 {
  display: inline-block;
  width: 225px;
}

button {
  &.btn-signin {
    margin-right: 0;
  }
}

footer {
  &.login {
    background-color: inherit;
    margin-left: 50%;
    padding-bottom: 0;
    padding-top: 0;
  }
}

#login-form {
  margin: 0 auto;
  text-align: center;

  & > * {
    font-size: 16px !important;
  }
}
